.login_btn1 {
  position: relative;
  padding: 0.5rem 3rem;
  background-color: white;
  color: #ffffff;
  border: 2px solid transparent; /* Make the border transparent */
  border-radius: 40px;
  background-image: linear-gradient(
    135deg,
    #e059f0,
    #9895f4
  ); /* Gradient background */
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-shadow: inset 0 0 0 2px transparent;
  transition: all 0.2s ease-in;
  font-size: 16px !important;
}

.login_btn1:hover {
  color: #ffffff;
  background-color: #e059f0;
  cursor: pointer;
}
/* 
.discount-banner {
  background: linear-gradient(120deg, #f7f7f7, #e0e0e0);
  color: #333;
  border: none;
  padding: 20px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
} */

.banner-content {
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.alert-heading {
  font-size: 2.6rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.offer-text {
  font-size: 1.5rem;
  margin: 10px 0;
  font-style: italic;
}

.timer {
  font-size: 1.7rem !important;
  margin-bottom: 15px;
  font-weight: 500;
}

.time-left {
  font-weight: bold;
  font-size: 1.9rem;
  color: #d9534f;
  margin: 0;
  padding: 0px 20px;
  /* border: 1px solid #cdcdcd; */
  /* box-shadow: 1px 2px 5px #cdcdcd; */
  /* list-style: arabic-indic; */
  margin: 1rem 0;
}
.offer_bg {
  background-image: url(../../../../../public/assets/img/offer/offer2.jpg);
}
.shop-button {
  background-color: #5cb85c; /* Bootstrap success color */
  color: white;
  border-radius: 30px;
  padding: 10px 30px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
  border: none;
}

.shop-button:hover {
  background-color: #4cae4c; /* Darker success color on hover */
  transform: scale(1.05);
}

.shop-button:focus {
  outline: none;
}

.offer_btn {
  border: 2px solid linear-gradient(135deg, #e059f0, #9895f4);
  padding: 0.5rem 3rem;
  /* background-color: white;
    color: #bd29d9; */
  background: var(--btn-gradient) !important;
  color: #fff !important;
  border-radius: 40px;
  /* font-weight: 600 !important; */
  transition: all 0.2s ease-in;
  font-size: 16px !important;
}

.img-fluid1 {
  /* max-height: 350px; 
    object-fit: cover;  */
  max-height: 450px; /* Limits the maximum height to 350 pixels */
  width: 100%; /* Makes the image width responsive, filling its container */
  height: auto; /* Automatically adjusts height to maintain aspect ratio */
  object-fit: cover; /* Ensures the image covers the entire container while preserving aspect ratio */
  display: block;
}

.corner-ribbon {
  width: 200px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 50px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Custom styles */

/* ------------------- lanidng offer page --------------- */
.offer-container {
  min-height: 100vh;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay {
  padding: 40px;
  border-radius: 10px;
  text-align: center;
}

.card {
  border: none;
  border-radius: 10px;
}

.old-offer-card {
  background-color: #d5d3d3; /* Light red for old offer */
}

.new-offer-card {
  background-color: #d5d3d3; /* Light green for new offer */
}

/* --list -- */
.premium-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  z-index: 1;
}

h1 {
  position: relative;
  z-index: 2;
  font-size: 2.5rem;
  font-weight: bold;
}

.lead {
  position: relative;
  z-index: 2;
  font-size: 1.25rem;
  margin-bottom: 30px;
}

.premium-card {
  border: none;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.9); /* Light background for card */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  z-index: 2; /* Stack above overlay */
}

.premium-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.card-title {
  font-weight: bold;
  font-size: 1.5rem;
  color: #333;
}

.card-text {
  font-size: 1.1rem;
  color: #555;
}
.time-tango-logo {
  height: 70px;
  /* margin-top: 12px; */
}
.contact-number {
  font-size: 1.2rem; /* Adjust font size */
  font-weight: bold; /* Make it bold */
  color: #bd29d9; /* Highlight color */
  background-color: rgba(255, 255, 255, 0.8); /* Optional: Light background */
  padding: 5px 10px; /* Add some padding */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Shadow for effect */
}

.vs-symbol {
  margin: 2rem 0;
}
.highlight {
  color: #f54d7b;
  text-decoration: underline;
}
ul .offerList {
  background-color: #7b67f5;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  font-size: 16px;
}

.offerTitle {
  color: #858585 !important;
  font-weight: 600;
}

.icon-circle {
  background-color: green; /* Green background */
  border-radius: 50%; /* Circular shape */
  width: 40px; /* Circle width */
  height: 40px; /* Circle height */
  display: flex; /* Flex to center the icon */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-right: 10px; /* Space between circle and text */
}

/* .icon {
  color: rgb(19, 19, 19) !important; 
  font-size: 20px; 
  margin-right: 10px; 
} */

/* ///footer  */
.footer {
  background-color: #bbbbbb82 !important; /* Dark background */
  color: #454444 !important; /* White text */
  padding: 20px 0; /* Padding */
  margin-top: 50px; /* Space above footer */
}

.footer h5 {
  margin-bottom: 20px; /* Space below heading */
}

.list-inline {
  padding-left: 0; /* Remove padding from ul */
  list-style: none; /* Remove bullet points */
}

.list-inline-item {
  display: inline-block; /* Inline list items */
  margin-right: 15px; /* Space between icons */
}

.social-icon {
  color: rgb(193, 97, 212); /* White color for icons */
  font-size: 30px; /* Icon size */
  transition: color 0.3s; /* Smooth transition for hover */
}

.social-icon:hover {
  color: #201b7a; /* Change color on hover */
}

p {
  margin-top: 15px;
}

a {
  text-decoration: none; /* No underline for links */
  color: inherit; /* Inherit color */
}

.background-image-container {
  background-image: url("../../../../../public/assets/img/offer/offer2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0; /* Adjust padding as needed */
  min-height: 100vh; /* Adjust height to ensure full coverage */
  position: relative; /* To ensure children are positioned relative to this container */
  z-index: 1;
}

.background-image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Add a dark overlay to make content more readable */
  z-index: -1; /* Ensure the background is behind the content */
}

/* umesh css added 3-oct */
.current_card,
.premium_card {
  /* height: 100% !important; */
  line-height: 4rem;
}
.premium_card {
  /* background: linear-gradient(150deg, #f25c34, #e22876 100%); */
  filter: drop-shadow(18px 36px 72px #cdcdcd);
}
.current_card ul {
  flex: 1;
}

.premium_card h3 {
  color: #212529;
  /* font-size: 34px; */
  font-weight: 500;
  text-align: center;
}
.premium_card ul li {
  font-size: 16px !important;
  color: #212529;
}
.current_card ul li {
  font-size: 16px !important;
}
.pricing_wrap {
  background-image: url(../../../../../public/assets/img/offer/card_bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.current_btn {
  outline: 1px solid #eb7294;
  color: #fff;
  /* font-weight: 600; */
  font-size: 18px;
  background: #f54d7b;
  padding: 0.59rem;
  margin-top: 50px;
  width: 30%;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.premium_btn {
  /* outline: 1px solid #eb7294; */
  color: #0c0c0c !important;
  /* font-weight: 600; */
  font-size: 18px;
  background: #ffffff;
  padding: 0.59rem;
}
.offer_container {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.offer_contact form button {
  outline: 1px solid #eb7294;
  color: #fff;
  /* font-weight: 600; */
  font-size: 18px;
  background: #f54d7b;
  padding: 0.59rem;
  border-radius: 6px;
}
.offer_contact form button:hover,
.premium_btn:hover {
  background-color: #fff;
  color: #0c0c0c !important;
  /* border: 1px solid #eb7294; */
}

.popup_btn {
  background: white;
  color: #34314c;
  outline: 1px solid #f54d7b;
  box-shadow: none;
  width: 100%;
  transition: all ease-in 0.4s;
  padding: 12px 24px;
  font-weight: 700;
  font-size: 18px;
}
.popup_btn:hover {
  background-color: #ffffff;
  font-weight: 500;
  width: 100%;
  padding: 12px 24px;
  font-weight: 700;
}
.popup_btn:hover {
  color: #ffffff;
}
.popup_btn_outline:hover {
  color: #0c0c0c;
}
.banner_off img {
  /* filter: drop-shadow(25px 50px 100px #f896b2);
  color: #f896b2; */
}
.bg-contained {
  background-color: #f54d7b !important;
  color: #fff;
}
.modal {
  backdrop-filter: blur(5px);
}
.time-left small {
  font-size: 12px !important;
}
.sign_up_btn {
  position: absolute;
  bottom: 10%;
  right: 10%;
  width: 20%;
}
.offer_hero {
  position: relative;
}
@media only screen and (max-width: 600px) {
  .modal-header .btn-close {
    position: absolute;
    top: -4%;
    right: 1%;
    filter: invert(1);
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .sign_up_btn {
    bottom: 5%;
    right: 10%;
    font-size: 9px;
    padding: 0.3rem;
  }
  .premium_card {
    margin-bottom: 2rem;
  }
  .current_card,
  .premium_card {
    /* height: 100% !important; */
    line-height: 3rem;
  }
  .current_card {
    /* margin-bottom: 2rem; */
    order: 3;
  }
  .premium_card{
    order: 1;
  }
  .vs-symbol-wrap{
    order: 2;
  }
  .popup_btn {
    margin-bottom: 1rem;
  }
  .alert-heading {
    font-size: 1rem;
  }
  .order-xs-4 {
    order: 4 !important;
  }
  .order-xs-3 {
    order: 3 !important;
  }
  .order-xs-2 {
    order: 2 !important;
  }
  .order-xs-1 {
    order: 1 !important;
  }
  /* .current_card,
  .premium-card {
    width: 75% !important;
  } */
}

.ribbon {
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 93px;
  height: 93px;
  text-align: right;
}
.ribbon span {
  font-size: 0.8rem;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 32px;
  transform: rotate(45deg);
  width: 125px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#9bc90d 0%, #79a70a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 17px;
  right: -29px;
}

.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79a70a;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0%;
  top: 100%;
  z-index: -1;
  border-right: 3px solid #79a70a;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79a70a;
}

.red span {
  background: linear-gradient(#f70505 0%, #8f0808 100%);
}
.red span::before {
  border-left-color: #8f0808;
  border-top-color: #8f0808;
}
.red span::after {
  border-right-color: #8f0808;
  border-top-color: #8f0808;
}

.section-padding {
  padding: 67px 0 !important;
}
.textHelp {
  color: #a269cf !important;
  font-size: 20px !important;
  font-weight: 600;
}

.arrow {
  margin-left: 8px;
  color: #f54d7b;
  transition: color 0.9s ease;
}

/* .popup_btn:hover .arrow {
  color: #ffffff; 
} */

@media only screen and (min-device-width: 768px) and (max-device-width: 1025px) and (-webkit-min-device-pixel-ratio: 1) {
  .current_card,
  .premium_card,
  .vs-symbol,
  .banner_off,
  .banner_content {
    width: 100% !important;
  }
  .modal-header .btn-close {
    position: absolute;
    top: -4%;
    right: 1%;
    filter: invert(1);
  }
  .current_card {
    /* margin-bottom: 2rem; */
    order: 3;
  }
  .premium_card{
    order: 1;
  }
  .vs-symbol-wrap{
    order: 2;
  }
  .btn_wrapper{
    flex-direction: column;
  }
  .popup_btn_outline{
    margin-top: 1rem !important;
  }
  .w-tab-100{
    width: 100% !important;
  }
}
