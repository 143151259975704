.fs-16-js {
  text-align: justify;
  font-size: 16px;
}

.heights {
  padding-top: 80px;
  padding-bottom: 10px;
  margin-top: 75px;
}

.custom-dot-list {
  list-style-type: none;
  font-size: 16px;
  padding-left: 10px;
}

.custom-dot-list li::before {
  content: "•";
  font-size: 1em;
  color: #000;
  padding-right: 10px;
  vertical-align: middle;
}

.bccolor {
  background-color: rgba(239, 223, 250, 1);
}

.fs-18 {
  font-size: 18px;
}
.p-20 {
  padding-left: 20px;
}

.col-3 {
  width: 18%;
}

.card-blog {
  position: sticky;
  top: 100px;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1024px) {
  .hidden {
    display: none;
  }
}
.card-sub-blog {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.fs-12 {
  font-size: 12px;
}

.ulist {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
}
.ulist li:hover {
  font-weight: bold;
}

.active {
  font-weight: bold;
  background-color: rgba(239, 223, 250, 1);
}

.card-manual {
  width: 48%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (max-width: 650px) {
  .card-manual {
    width: 100%;
  }
}
.react-link {
  cursor: pointer !important; /* Change cursor to pointer on hover */
}