@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,700;9..40,900&family=Montserrat:ital,wght@0,500;0,600;1,400&display=swap");

body {
  /* padding-top: 70px; */
  font-family: "DM Sans" !important;
  background-color: "#ffffff" !important;
}
.section-padding {
  padding: 100px 0 !important;
}
@media (max-width: 768px) {
  .section-padding {
    padding: 50px 0 !important;
  }
}

.paddingx {
  padding-left: 20px;
  padding-right: 20px;
}

.component-padding {
  padding: 70px 0 !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.h-vh-100 {
  height: 100vh !important;
  min-width: 100vh !important;
}
.feature_hero img {
  height: 100%;
  width: 100%;
}
.tool ul li {
  list-style-type: disc !important;
  list-style-position: inside;
}
.bg-gradient {
  background: linear-gradient(
    90deg,
    rgba(239, 223, 250, 1) 35%,
    rgba(247, 236, 231, 1) 100%
  ) !important;
}
.cta-btn {
  /* border: 1px solid #090909; */
  padding: 0.7rem 2.5rem;
  border-radius: 27px;
  /* margin: 2rem 0; */
  color: #000;
  font-size: 16px;
  background-color: darkmagenta;
  color: #fff;
}
.bg-smooth {
  background-color: #fafafa !important;
}
.about_bg {
  background: linear-gradient(
    90deg,
    rgba(239, 223, 250, 1) 35%,
    rgba(247, 236, 231, 1) 100%
  );
  height: 100vh;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
}
@media (max-width: 768px) {
  .about_bg {
    background: linear-gradient(
      90deg,
      rgba(239, 223, 250, 1) 35%,
      rgba(247, 236, 231, 1) 100%
    );
    height: auto;
  }
}
.about_banner_img img {
  border-radius: 20px;
  aspect-ratio: 2/1;
  object-fit: cover;
}
.quote {
  transform: rotate(180deg);
  font-size: 40px;
  color: #cccccc !important;
  /* position: absolute; */
}
.fs-18 {
  font-size: 18px !important;
}
.fs-16 {
  font-size: 16px !important;
  margin-bottom: 0px;
  text-align: left;
}
.mt-70 {
  margin-top: 70px;
}
.standred-margin {
  margin-top: 90px;
}

.headingcol {
  color: #af11cd;
}

.bg-commn {
  width: 100%;
  background-color: #f4f0f5;
}

.comp2para {
  /* font-size: 18px; */
  font-size: 16px;
}

.comp2padding {
  padding: 20px;
}

.comp2margin {
  margin-top: 40px;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
}

li {
  margin-bottom: 10px;
}

.paddingy {
  padding-top: 20px;
  padding-bottom: 20px;
}

.featureImg {
  width: 90%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  padding-top: 55px;
  text-align: center;
}

@media (max-width: 768px) {
  .featureImg {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    padding-top: 55px;
    text-align: center;
  }
}

.mt-10 {
  margin-top: 50px; /* Mobile default */
}

@media (min-width: 650px) {
  /* Desktop and up */
  .yourClass {
    margin-top: 100px;
  }
}

.cstmbg {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.login_btn {
  border: 2px solid linear-gradient(135deg, #e059f0, #9895f4);
  padding: 0.5rem 3rem;
  /* background-color: white;
  color: #bd29d9; */
  background: var(--btn-gradient) !important;
  color: #fff !important;
  border-radius: 40px;
  /* font-weight: 600 !important; */
  transition: all 0.2s ease-in;
  font-size: 16px !important;
}

.price-table {
  overflow: hidden;
  /* background: #eeeeee52 !important; */
  background: white;
  /* padding-top: 40px; */
  /* padding-bottom: 40px; */
}

.login_btn:hover {
  /* background-color: #bd29d9; */
  color: #ffffff;
  cursor: pointer;
}

/* price section start */
.price-heading p {
  font-size: 17px;
  color: #666;
  position: relative;
  display: inline-block;
  padding-bottom: 30px;
  padding-top: 1px;
}

.LandingPagebtn {
  padding: 5px 19px;
  border: 1px solid #a769e0;
  color: #a769e0;
  font-size: 16px;
  /* font-family: rb; */
  border-radius: 50px;
}

.plan-heading {
  color: #e86605;
  font-size: 23px;
  text-align: center !important;
}

.price-table {
  overflow: hidden;
  /* background: #eeeeee52 !important; */
  background: white;
  /* padding-top: 40px; */
  /* padding-bottom: 40px; */
}

.price-table .price-box {
  background: #fff;
  padding: 45px 30px 55px;
  border: 1px solid #ddd;
  border-bottom: 3px solid #1e1a76;
  margin-bottom: 15px;
  /* box-shadow: 0 0 10px 5px rgba(203, 202, 202, 0.2); */
}

.price-table .price-box h2 {
  /* margin: 5px 0 15px; */
  color: #363535;
}

.price-table .price-box h2 b {
  color: #a769e0;
}

/* .price-table .price-box:hover p {
    -webkit-transform: scale(1.1, 1.1) rotate(360deg);
    -moz-transform: scale(1.1, 1.1) rotate(360deg);
    -o-transform: scale(1.1, 1.1) rotate(360deg);
    transform: scale(1.1, 1.1) rotate(360deg);
    border: 2px solid #a769e0;
  } */
.price-table .price-box p {
  font-size: 35px;
  font-weight: bold;
  color: #666;
  width: 100px;
  height: 100px;
  background: #f3f3f3;
  border-radius: 50%;
  line-height: 100px;
  border: 2px solid #fff;
}

.price-table .price-box ul {
  line-height: 250%;
  font-size: 15px;
}
.price-table .price-box a {
  margin-bottom: 5px;
}
.price_item_wrapper {
  margin-top: 0.5rem !important;
  column-gap: 1rem;
  padding: 1rem;
}

.price_btn {
  background: var(--btn-gradient);
  /* background: #375a64 !important; */
  /* border: 1px solid #bd29d9; */
  border-radius: 20px;
  padding: 0.5rem !important;
  text-decoration: none !important;
  font-weight: 500;
  transition: all 0.2s ease-in;
  display: inline-block;
  width: 50%;
  align-self: center;
  /* box-shadow: 2px 2px 6px #375a64 !important; */
}
/* .price_btn:hover {
  box-shadow: #cdcd 0px 9px 20px -5px !important;
  background: linear-gradient(180deg, #ff6a00, #ffb900);
} */
.price-item .price_head h3 {
  font-weight: 400;
}
.price_btn a {
  color: white;
}

.price-table .price-box a:hover {
  background-color: #a769e0;
  border-color: #a769e0;
  color: white;
}
#price-section .price-item {
  box-shadow: 1px 1px 3px #ddd6d6;
  padding: 2rem;
  text-align: center;
  border-radius: 7px;
  height: 350px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* border: 1px solid #e5e5e5; */
}

/*End Section Price Table*/

/* for card */
.card {
  width: 28%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (max-width: 1024px) {
  .card {
    width: 48%;
  }
}

@media (max-width: 650px) {
  .card {
    width: 100%;
  }
}
.card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

/* Styling for Faq's */

.faq-questions {
  cursor: pointer;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  width: 80%;
  background-color: rgba(239, 223, 250, 1);
}

.faq-questions:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3);
}

.faq-answers {
  padding: 5px;
  border-radius: 5px;
  width: 80%;
  padding: 10px;
  background-color: #f9f9f9;
}

.pt-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.wf-80 {
  width: 80%;
  height: 80%;
}

@media (max-width: 1024px) {
  .pt-100 {
    padding-top: 0px;
    padding-bottom: 60px;
  }
  .wf-80 {
    width: 100%;
    height: 100%;
  }
}

/* Styles for Mobile Screens */
@media (max-width: 768px) {
  h1 {
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 16px;
  }
  .fs-16 {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 12px;
    text-align: justify;
  }
}

/* Styles for Mobile Screens */
@media (max-width: 1024px) {
  .mt-80 {
    margin-top: 80px;
  }
}

@media only screen and (width: 640px) and (height: 360px) {
  .scrollable {
    width: 100%;
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    border: 1px solid #ccc;
    scrollbar-width: none;
  }
  .section-padding {
    padding: 250px 0px !important;
  }
  #home {
    height: 100vh;
  }
}
