@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,700;9..40,900&family=Montserrat:ital,wght@0,500;0,600;1,400&display=swap");

/* *{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
} */
:root {
  --btn-gradient: linear-gradient(135deg, #e059f0, #9895f4);
  /* --btn-gradient: #e059f0; */
  --bg-gradient: linear-gradient(41deg, #ff6a00, #ffb900);
}
body {
  /* padding-top: 70px; */
  font-family: "DM Sans" !important;
  background-color: "#ffffff" !important;
}
.sepreter {
  opacity: 1;
  color: #a669e03c;
  margin: 50px 0px;
}
h1,
h2,
h3,
h4,
h5,
:hover .lead {
  font-family: "DM Sans" sans-serif !important;
}
p {
  line-height: 1.6 !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3 !important;
}
.design_hr {
  width: 100px;
  height: 4px;
  margin: 0px auto 0px auto;
  background-color: #bb72ff;
  color: #a769e0;
  opacity: 1;
}

.navbar {
  height: 145px;
  background-color: #f7f7f7;
  border-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.navbar-light .navbar-nav .nav-link {
  color: #131313;
  font-size: 16px;
  position: relative;
  margin: 5px;
}

.navbar-light .navbar-nav > li > a:hover {
  color: #b81cd4;
}
.active-link {
  color: #b81cd4 !important; /* Change this to your desired active link color */
}
.logo-header {
  display: table;
  float: left;
  vertical-align: middle;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 150px;
  height: 70px;
  position: relative;
  z-index: 9;
}

.time-tango-logo {
  height: 70px;
  /* margin-top: 12px; */
}

.customStyledContainer {
  padding-top: 10px;
}

/*home Section*/
#home {
  height: 100vh;
}
.banner_content .bannerTagLine {
  text-transform: capitalize;
}

.component-padding {
  padding: 70px 0 !important;
}

.bannerTagLine {
  font-size: 2.5rem;
}
.btn_custom {
  padding: 1rem 3rem;
  border-radius: 40px;
  background: var(--btn-gradient);
  color: #fff;
  transition: all 0.3s ease-in;
  /* margin-top: 2rem;
  width: 40%; */
}
.btn_custom:hover {
  box-shadow: 2px 2px 12px #cdcdcd;
}
/* 
#banner {
  padding: 60px 0 60px;
  background-color: white;
} */

.banner-text {
  position: absolute;
  top: 25%;
  left: 20%;
  width: 60%;
  text-align: center;
}

.banner-text h1 {
  font-size: 50px;
  padding-bottom: 3px;
  color: #0f2137;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: "DM Sans" sans-serif !important;
  text-transform: capitalize !important;
}

.banner-text h4 span {
  font-size: 35px;
  color: rgba(34, 42, 69, 0.96);
}

.desh2 {
  padding-bottom: 4px;
  font-size: 36px;
  padding-top: 1px;
  color: #3c3c3c;
}

.banner-text h6 {
  padding-top: 0 !important;
  padding-bottom: 10px !important;
  padding-right: 0 !important;
  margin-bottom: 0 !important;
  font-size: 20px;
  line-height: 26px;
  padding-top: 6px;
  padding-bottom: 15px;
  padding-right: 68px;
}

.banner-text p i {
  margin-right: 2% !important;
  color: #e86605 !important;
  font-size: 11px !important;
}

.img-fluid_banner {
  max-width: 100% !important;
  height: auto;
}

.img-fluid {
  max-width: 50% !important;
  height: auto;
}

.paddingTop7 {
  padding-top: 50px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.mix-a {
  font-size: 16px;
  color: white !important;
  font-weight: 500 !important;
  background: var(--btn-gradient);
  /* border: 1px solid #375a64; */
  border-radius: 50px;
  padding: 0.89rem 3rem !important;
  text-decoration: none !important;
  font-weight: 500;
  transition: all 0.2s ease-in;
  /* box-shadow: 2px 2px 8px #bd29d9 !important; */
}

a {
  text-decoration: none !important;
}

.mix-a:hover {
  /* background-color: #1e1a77;
  border-color: #1e1a77; */
  /* box-shadow: #375a64 0px 9px 20px -5px; */
}

/*home Section End*/
/*feature section*/

.about-text p i {
  margin-right: 2% !important;
  color: #e86605 !important;
  font-size: 11px !important;
}

.f-item h4 {
  font-size: 28px;
  padding-bottom: 3px;
  color: #0f2137;
  font-family: "DM Sans" sans-serif !important;
}

.f-item p {
  font-size: 17px;
  color: #666;
  line-height: 27px;
  padding-top: 3px;
}

.navbar-inverse .navbar-brand {
  color: #bbb;
  font-size: 30px;
  line-height: 40px;
}

.navbar-inverse .navbar-brand span {
  color: #fff;
}

/*Navbar Toggle*/
.navbar-toggle {
  margin-top: 18px;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>") !important;
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
/**********End Overriding Bootstrap********/
/*Start Section About*/
.about {
  padding-top: 60px;
  padding-bottom: 60px;
}

.about .about-paragraph1 {
  color: #888;
  font-size: 25px;
  margin-top: 15px;
}

.about .about-paragraph2 {
  color: #888;
  font-size: 17px;
  margin-top: 15px;
}

.about h1 {
  font-weight: bold;
}

.about h2 {
  font-weight: bold;
}

.about h1 span {
  color: #e41b17;
}

.about h2 span {
  color: #e41b17;
}

/*End Section About*/
/*Start Section Features*/
.features {
  overflow: hidden;
  background-color: #eeeeee52;
  padding-top: 50px;
  padding-bottom: 50px;
}

.features .feat {
  margin-bottom: 30px;
}

.features .feat p {
  line-height: 2em;
}

.features .feat span.glyphicon {
  font-size: 20px;
  margin-bottom: 10px;
  color: #666;
}

#morefeature {
  padding-top: 50px;
  /* background: #eee; */
  background: white;
}

/*End Section Testimonials*/
/*Start Section Price Table*/

.price-heading p {
  font-size: 17px;
  color: #666;
  position: relative;
  display: inline-block;
  padding-bottom: 30px;
  padding-top: 1px;
}

.LandingPagebtn {
  padding: 5px 19px;
  border: 1px solid #a769e0;
  color: #a769e0;
  font-size: 16px;
  /* font-family: rb; */
  border-radius: 50px;
}

.plan-heading {
  color: #e86605;
  font-size: 23px;
  text-align: center !important;
}

.price-table {
  overflow: hidden;
  /* background: #eeeeee52 !important; */
  background: white;
  /* padding-top: 40px; */
  /* padding-bottom: 40px; */
}

.price-table .price-box {
  background: #fff;
  padding: 45px 30px 55px;
  border: 1px solid #ddd;
  border-bottom: 3px solid #1e1a76;
  margin-bottom: 15px;
  /* box-shadow: 0 0 10px 5px rgba(203, 202, 202, 0.2); */
}

.price-table .price-box h2 {
  /* margin: 5px 0 15px; */
  color: #363535;
}

.price-table .price-box h2 b {
  color: #a769e0;
}

/* .price-table .price-box:hover p {
    -webkit-transform: scale(1.1, 1.1) rotate(360deg);
    -moz-transform: scale(1.1, 1.1) rotate(360deg);
    -o-transform: scale(1.1, 1.1) rotate(360deg);
    transform: scale(1.1, 1.1) rotate(360deg);
    border: 2px solid #a769e0;
  } */
.price-table .price-box p {
  font-size: 35px;
  font-weight: bold;
  color: #666;
  width: 100px;
  height: 100px;
  background: #f3f3f3;
  border-radius: 50%;
  line-height: 100px;
  border: 2px solid #fff;
}

.price-table .price-box ul {
  line-height: 250%;
  font-size: 15px;
}
.price-table .price-box a {
  margin-bottom: 5px;
}
.price_item_wrapper {
  margin-top: 0.5rem !important;
  column-gap: 1rem;
  padding: 1rem;
}
.navbar.navbar-4 .navbar-toggler-icon {
  /* background-image: url("https://mdbootstrap.com/img/svg/hamburger7.svg?color=a769e0"); */
}
.price_btn {
  background: var(--btn-gradient);
  /* background: #375a64 !important; */
  /* border: 1px solid #bd29d9; */
  border-radius: 20px;
  padding: 0.5rem !important;
  text-decoration: none !important;
  font-weight: 500;
  transition: all 0.2s ease-in;
  display: inline-block;
  width: 50%;
  align-self: center;
  /* box-shadow: 2px 2px 6px #375a64 !important; */
}
/* .price_btn:hover {
  box-shadow: #cdcd 0px 9px 20px -5px !important;
  background: linear-gradient(180deg, #ff6a00, #ffb900);
} */
.price-item .price_head h3 {
  font-weight: 400;
}
.price_btn a {
  color: white;
}

.price-table .price-box a:hover {
  background-color: #a769e0;
  border-color: #a769e0;
  color: white;
}
#price-section .price-item {
  box-shadow: 1px 1px 3px #ddd6d6;
  padding: 2rem;
  text-align: center;
  border-radius: 7px;
  height: 350px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* border: 1px solid #e5e5e5; */
}

/*End Section Price Table*/

#contact-us {
  /* padding: 70px 0; */
  /* background: #eee; */
  background: white;
}

.contact-input input {
  border-radius: 0px;
  cursor: pointer;
  outline: none;
}
input:focus {
  outline: 0 none !important;
  box-shadow: none;
}
input::-moz-focus-inner {
  border: 0;
}
.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-group textarea {
  border-radius: 0px;
  outline: none !important;
}
.contact-heading h3 span {
  color: #ea751d;
}
.contact-heading-nm {
  padding-top: 11px !important;
}
.contact-heading p {
  font-size: 17px;
  color: #666;
  position: relative;
  display: inline-block;
  /* padding-bottom: 38px; */
  padding-top: 1px;
}

.new-nb {
  margin-bottom: 15px !important;
}

/*Start Section Footer*/
#footer {
  padding: 50px 20px 10px 20px;
  /* background: #fcfcfc; */
  background: #f9f9f9;
  /* border-top: 1px solid #dedede; */
  position: relative;
}

/* abhijit css  */
#footer .links h3 {
  text-decoration: 1px underline #a769e0;
  text-underline-offset: 5px;
}
.f-logo {
  font-size: 25px;
  color: #363535;
  -webkit-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.footerlogoCls {
  max-width: 70px;
}

.footer-logo p {
  font-weight: 400;
  color: #454444;
  padding-top: 17px;
  line-height: 26px;
  font-size: 17px;
}

.links h3 {
  font-size: 19px;
  color: #363535;
  font-weight: 600;
  text-transform: capitalize;
  padding-bottom: 4px;
}

.links h3 {
  font-size: 19px;
  color: #363535;
  font-weight: 600;
  text-transform: capitalize;
  padding-bottom: 4px;
}

li,
ul {
  list-style-type: none;
  text-decoration: none;
  /* padding-left: unset !important; */
}

.links ul li {
  padding-left: unset !important;
}

.links ul li a {
  color: #454444;
  text-transform: capitalize;
  line-height: 38px;
  font-size: 17px;
  /* font-family: rr; */
  -webkit-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
  transition: all linear 0.3s;
  text-decoration: none;
}

/* #footer .hover1:hover {
  color: #b81cd4;
} */
#footer .text-color {
  color: #b81cd4;
}
#footer i {
  color: #a769e0;
}
#footer a {
  color: #454444;
}

.link {
  color: #454444;
  text-transform: capitalize;
  line-height: 38px;
  font-size: 17px;
  /* font-family: rr; */
  -webkit-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
  transition: all linear 0.3s;
  text-decoration: none;
}

.location p a {
  text-decoration: none;
}

#footer-btm {
  padding: 15px 0;
  /* background: white; */
}

.moja-loss {
  padding-top: 10px;
  padding-bottom: 10px;
}

.fop-btm h2 {
  color: #363535;
  font-size: 16px;
  padding-top: 10px;
}

.fop-btm a {
  color: #a769e0;
  /* font-family: rb; */
}

/* .new-px {
  padding-top: 15px !important;
} */

.padding-ten {
  padding-top: 10px;
}

/*end Section carousel */
/*Start Our Grouping*/
.contact-us {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.features .h1,
.features h2 {
  margin-bottom: 30px;
}

.price-table .price-box p,
.footer .social-list li {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.features_wrapper {
  padding: 2rem;
  justify-content: space-between;
}
.features_section .feature_item {
  padding: 1rem;
  margin: 0.2rem;
  text-align: center;
  transition: all 0.3s ease-in;
  height: calc(100% - 30px);
}
.feature_item:hover {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 6px #cdcdcd;
  transform: translateY(-5px);
}
/* .features_section .feature_item:hover {
  box-shadow: 2px 7px 13px #cdcdcd;
} */
.features_section .feature_item p {
  text-align: left;
  color: #0f2137;
  font-family: "DM Sans" sans-serif !important;
}

.features_section h4,
.section_2 h4,
.diff_section h3,
.industries h4,
.testimonial_section h4,
#price-section h4 {
  position: relative;
  padding: 0;
  /* color: #af11cd; */
  line-height: 1;
  letter-spacing: 0.3px;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  margin-bottom: 20px;
  font-family: "DM Sans " sans-serif !important;
  text-transform: capitalize;
}
.diff_section img {
  width: 70% !important;
}
/* .industries {
  background-image: url(https://theme.dsngrid.com/blackdsn/dark/assets/img/bg-dot-light.png);
} */
p {
  font-family: "DM Sans " sans-serif !important;
  letter-spacing: 0.4px !important;
  /* color: #454545 !important; */
}
.client_card {
  transition: all ease-in 0.3s;
}
.client_card:hover {
  /* box-shadow: none !important; */
  /* transform: scale(1.1) */
  /* transform: translateX(5px); */
}
.quote_icn {
  position: absolute;
}
.card-text {
  font-size: 16px !important;
}
.social_link_wrapper li {
  width: 40px;
  margin-top: 2rem;
}
.quote-icn {
  filter: opacity(0.5);
}
.features_section h4 span,
.section_2 h4 span,
.diff_section h4 span,
#price-section h4 span {
  color: #af11cd;
}

.section_2 h3 span {
  color: #af11cd;
}

.diff_section .difference-img {
  text-align: center;
}
.section_2 .img-wrap {
  /* height: 400px;
  width: 550px; */
}

.section_2 .img-wrap img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.features_section .feature_item h6 {
  font-size: 18px;
  font-weight: 600;
  /* color: #454545 !important; */
}
.features_section .feature_item h3 {
  font-size: 18px;
  font-weight: 500;
}

.features_section h4 span,
.section_2 h4 span {
  /* font-weight: 700; */
  padding-bottom: 5px;
  /* color: #2f2f2f; */
}

.features_section p {
  color: #818181;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  padding: 4px;
}

.item {
  background: #fff;
  text-align: center;
  padding: 30px 25px;
  -webkit-box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.07);
  margin-bottom: 30px;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}

.item:hover {
  /* background:#f91942; */
  /* box-shadow:0 8px 20px 0px rgba(0, 0, 0, 0.2); */
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}

.item:hover .item,
.item:hover span.icon {
  /* background:#fff; */
  border-radius: 10px;
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}

.item:hover h6,
.item:hover p {
  /* color:#fff; */
  -webkit-transition: all 0.5s ease 0;
  transition: all 0.5s ease 0;
  transition: all 0.5s ease 0s;
}

.item .icon {
  font-size: 40px;
  margin-bottom: 25px;
  color: #c72ce0;
  width: 90px;
  height: 90px;
  line-height: 96px;
  border-radius: 50px;
}

.item p {
  font-size: 15px;
  line-height: 26px;
}

.item h6 {
  margin-bottom: 15px;
  /* color:#2f2f2f; */
  color: #1b176f;
  font-weight: 600;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  height: 80px;
}

.banner_img img {
  filter: drop-shadow(2px 4px 8px #cdcdcd);
}
.homePage {
  /* display: grid;
  place-items: center; */
}
.slick-prev:before {
  content: "\2190" !important;
  color: #c9c9c9 !important;
  font-size: 30px !important;
}

.slick-next:before {
  content: "\2192" !important;
  color: #c9c9c9 !important;
  font-size: 30px !important;
}
ul {
  padding: 0;
  list-style: none;
}

.price-plan {
  border-bottom: 3px solid #b81cd4;
  text-align: center !important;
  width: 41%;
  margin: 0 auto;
}

/* Umesh code changes 04-august-2023 */

.login_btn {
  border: 2px solid linear-gradient(135deg, #e059f0, #9895f4);
  padding: 0.5rem 3rem;
  /* background-color: white;
  color: #bd29d9; */
  background: var(--btn-gradient) !important;
  color: #fff !important;
  border-radius: 40px;
  /* font-weight: 600 !important; */
  transition: all 0.2s ease-in;
  font-size: 16px !important;
  text-align: center;
}

.login_btn:hover {
  /* background-color: #bd29d9; */
  color: #ffffff;
  cursor: pointer;
}
.hero_text {
  font-size: 20px;
  font-family: "DM Sans";
  font-weight: normal;
}

/* .left-svg {
  position: absolute;
  z-index: -1;
  left: -6%;
  top: 25%;
}
.right-svg {
  position: absolute;
  z-index: -1;
  right: 0%;
  top: 50%;
  overflow: hidden;
} */

.hero_bg {
  height: 100%;
  width: 100%;
}
.hero_bg img {
  height: 100%;
  width: 100%;
}
.section-padding {
  padding: 100px 0 !important;
}

/* .hero_section {
  background-image: url(../../../../public/assets/hero.svg);
} */

.section_2,
.botom_section,
.diff_section {
  background-color: #f8f8f8;
}
.section_2 {
  /* clip-path: polygon(0 0, 100% 0%, 100% 85%, 50% 100%, 0 85%); */
}
#contact-us {
  /* background: url(../../../../public/assets/bg1.png),
    linear-gradient(rgba(255, 255, 255, 0.511), rgba(255, 255, 255, 0.457));
  background-attachment: fixed;
  background-blend-mode: overlay; */
}
.section_2_content {
  align-items: center;
  justify-content: space-around;
}
.section_2_content p {
  font-size: 16px;
  font-family: "DM Sans" sans-serif;
  font-weight: normal;
  color: #0f2137;
  letter-spacing: 0.5px;
}

/* features */
.features_section .feature_content,
.diff_section p,
#price-section p {
  font-size: 16px;
  font-weight: normal;
  font-family: "DM Sans" sans-serif !important;
  color: #0f2137;
}

.feature_img {
  display: grid;
  place-items: center;
  height: 60px;
  background: var(--bg-gradient);
  width: 60px;
  text-align: center;
  border-radius: 33%;
  box-shadow: #ff6700 0px 9px 20px -5px;
  padding: 15px 0;
  margin-bottom: 1rem;
  margin: 1rem auto;
}
.feature_img img {
  width: 28px;
}

.botom_section {
  text-align: center;
  color: #ffffff;
  /* background: linear-gradient(135deg, #e059f0, #9895f4, #e059f0); */
  /* background-image: url(https://www.letsjive.io/images/marketing/home-shapes-tablet.svg); */
  background-image: url(../../../../public/assets/icons/blob.svg);
  background-repeat: no-repeat;
  /* animation: spinBg 1s infinite linear; */
}
.txt_btn {
  box-shadow: 2px 4px 8px #cdcdcd;
  padding: 0.8rem 3rem;
  border-radius: 30px;
  font-weight: 600;
  color: #793cb1;
  font-size: 16px;
}
.testimonial_section .card {
  border: 0;
  box-shadow: 1px 1px 4px #eaeaea;
}
.client_org {
  color: #bd2cd7 !important;
  font-weight: 600 !important;
}
@keyframes spinBg {
  0% {
    background: linear-gradient(0deg, #e059f0, #9895f4);
  }
  20% {
    background: linear-gradient(90deg, #f0eb59, #474593);
  }
  40% {
    background: linear-gradient(180deg, #c73b75, #9c9bbf);
  }
  60% {
    background: linear-gradient(270deg, #b329c2, #ca263f);
  }
  80% {
    background: linear-gradient(360deg, #48bb12, #30c74b);
  }
  100% {
    background: linear-gradient(450deg, #e059f0, #9895f4);
  }
}
.botom_section h4 {
  font-size: 30px;
}
.botom_section p {
  font-size: 18px;
}

.botom_section .get_touch_btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: all ease-in 0.2s;
}
.botom_section .get_touch_btn a {
  border: 1px solid #fff;
  color: #793cb1 !important;
  /* padding-top: 2rem; */
  padding: 0.6rem 2rem;
  /* border-radius: 50px; */
  background: #ffffff !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  box-shadow: 2px 2px 8px #d3cece;
}
/* .botom_section .get_touch_btn a:hover {
  background: #ffffff;
  color: #a769e0 !important;
} */
.navbar-toggler:focus {
  box-shadow: none !important;
}
/* 31st-jan-2024 umesh */
.img_wrapper {
  height: 40px !important;
  width: 40px !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
  /* background-color: #cb8d2f; */
  background: var(--bg-gradient);
  box-shadow: #ff6700 0px 9px 20px -5px;
}
.img_wrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.card-custom {
  width: 20% !important;
  margin: 1rem;
}
.fw-semibold {
  font-weight: 500 !important;
}
/* #price-section {
  background-color: #c1282852 !important;
} */
.bg_secondary {
  background-color: #eeeeee52 !important;
}
.social_link_wrapper li {
  height: 30px;
  width: 30px;
  margin-right: 1rem;
}
.social_link_wrapper li a img {
  height: 100%;
  width: 100%;
}
/* 31st-jan-2024 umesh end */

.carousel-indicators {
  bottom: -40px !important;
}
.carousel-indicators [data-bs-target] {
  background-color: #b81cd4;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

/*Smart Phones and Tablets*/

@media (max-width: 480px) {
  .px-xs-0 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .card-custom {
    width: 100% !important;
    margin: 1rem;
  }
  .navbar-inverse .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: none;
  }

  h1 {
    font-size: 30px;
  }

  #home .banner-text {
    margin-bottom: 3rem;
  }

  #banner {
    background-color: white;
  }
  .banner-text h1 {
    font-size: 28px !important;
    font-weight: 500 !important;
  }
  .features_section h4,
  .section_2 h4,
  .diff_section h4,
  #price-section h4,
  .industries h4,
  .testimonial_section h4,
  .banner_content .bannerTagLine {
    font-size: 28px !important;
  }
  .banner-text {
    left: 0% !important;
    width: 100% !important;
    padding: 1rem;
  }
  #home {
    height: 100vh;
  }
  #home .row {
    flex-direction: column-reverse !important;
  }
  .section-padding {
    padding: 50px 0 !important;
  }
  .featureImg {
    text-align: center;
  }
  .flex-col-reverse {
    flex-direction: column-reverse !important;
    margin-bottom: 1rem !important;
  }

  .features_section .feature_content,
  .diff_section p,
  #price-section p,
  .section_2_content p {
    font-size: 16px !important;
    letter-spacing: 0.5px !important;
    text-align: justify;
  }
  .f-item h3 {
    font-size: 22px !important;
  }
  .price_spec p {
    text-align: center !important;
  }
  .navbar-nav {
    background-color: #ffffff;
    /* border-bottom: 1px solid #000; */
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  .section-space {
    padding: 3px 0 !important;
  }
  .features_wrapper {
    padding: unset !important;
  }
  /* .feature_item p {
    text-align: center !important;
  } */
  /* .login_btn {
    position: absolute;
    left: 35%;
    padding: 0.5rem 2rem !important;
    z-index: 99;
    top: 26;
  } */
  .section_text {
    width: 95% !important;
    margin: auto !important;
  }
  .py-xs-4 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
}

@media (max-width: 480px) {
  .login_btn {
    width: 80%;
  }
  .features_section .feature_item {
    padding: 0;
  }
  .text-xs-left {
    text-align: left !important;
  }
  .m-xs-2 {
    margin: 0 1rem !important;
  }
  .pt-sm-5 {
    padding-top: 5rem !important;
  }
}
@media (min-width: 468px) and (max-width: 670px) {
  .login_btn {
    width: 60%;
  }
  .navbar-inverse .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: none;
  }
  .text-xs-left {
    text-align: left !important;
  }
  h1 {
    font-size: 30px;
  }

  .h1 {
    font-size: 30px;
  }

  #banner {
    background-color: white;
    padding: 0px;
  }
  .text-xs-left {
    text-align: left !important;
  }
  .card-custom {
    width: 25% !important;
  }
}

@media (min-width: 671px) and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .card-custom {
    width: 25% !important;
  }
  .login_btn {
    width: 40%;
  }
  .navbar-inverse .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .text-xs-left {
    text-align: left !important;
  }
  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: none;
  }

  h1 {
    font-size: 30px;
  }

  .h1 {
    font-size: 30px;
  }
  .navbar-nav {
    background-color: #ffffff;
    border-bottom: 1px solid #000;
  }
  #home {
    height: 100vh;
  }
  .banner-text {
    position: absolute;
    top: 18%;
    left: 0%;
    width: unset;
  }
  .banner-text h1 {
    font-weight: 500 !important;
  }
  .features_section p {
    text-align: left;
  }
  .section_text {
    width: 100% !important;
  }
  .text-xs-left {
    text-align: left !important;
  }
}
@media (min-width: 809px) and (max-width: 912px) {
  .w-sm-100 {
    width: 100% !important;
  }
}
@media only screen and (min-device-width: 540px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  /* .login_btn {
    left: 70%;
    padding: 0.5rem 2rem !important;
    position: absolute;
    z-index: 99;
    top: 26%;
  } */

  .card-custom {
    width: 25% !important;
  }
  .text-xs-left {
    text-align: left !important;
  }
  .navbar-nav {
    background-color: #ffffff;
    border-bottom: 1px solid #000;
  }
  .navbar-inverse .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: none;
  }
  #home {
    height: 100vh;
  }
  .banner-text {
    position: absolute;
    top: 18%;
    left: 0%;
    width: unset;
  }
  .banner-text h1 {
    font-weight: 500 !important;
  }
  .features_section p {
    text-align: left !important;
  }
  .section_text {
    width: 100% !important;
  }
  .features_section .feature_item {
    padding: unset !important;
  }
  .price_btn {
    width: 100% !important;
  }
  .price_item_wrapper {
    column-gap: 1rem !important;
  }
  #price-section .price-item {
    padding: 1rem !important;
  }
}

/* @media (max-width: 600px) {
  .banner-text {
    position: absolute;
    top: 25%;
  }
} */

/* media query 375px mobile  */

@media only screen and (max-width: 480px) {
  .navbar .collapse {
    box-shadow: 0px 15px 10px -15px #c3c3c3;
    border: 0px 1px 0px 1px solid #000;
  }
  .navbar .collapse ul {
    padding-left: 20px;
  }
  .navbar-light .navbar-nav .nav-link {
    margin: 5px 5px 5px 9px;
  }
  .logo-header {
    padding-left: 15px;
  }
  #footer {
    padding: 50px 20px 10px 20px;
    border-top: 1px solid lightgray;
  }
  .fop-btm h2 {
    color: #363535;
    font-size: 14px;
    padding-top: 10px;
  }
  .fop-btm h2 {
    text-align: center;
  }
}

/* media query 768px Tab  */
@media only screen and (max-width: 768px) {
  .navbar .collapse {
    box-shadow: 0px 15px 10px -15px #c3c3c3;
  }
  .fop-btm h2 {
    text-align: left;
  }
}

/* media query 1080px Laptop  */

@media only screen and (min-width: 1080px) {
  .fop-btm h2 {
    text-align: left !important;
  }
}

.paddingx {
  padding-left: 20px;
  padding-right: 20px;
}

.slick-prev,
.slick-next {
  display: none !important;
}
.copy_right {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Common dropdown styles */

.dropdown,
.dropdown2 {
  display: none;
  position: absolute;
  left: 0%;
  top: 100%;
  background-color: white;
  padding: 8px;
  border: 2px solid white;
  border-radius: 2px;
  text-align: start;
}

/* Default widths for larger screens */
.dropdown {
  width: 250px;
}

.dropdown2 {
  width: 150px;
}

.dropdown.show {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dropdown2.show {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dropdown ul,
.dropdown2 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li,
.dropdown2 li {
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
  text-shadow: #000;
  color: rgb(0, 0, 0);
  transition: color 0.3s ease;
}

/* Line after each list item except last */
.dropdown li:not(:last-child)::after,
.dropdown2 li:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.dropdown li:hover,
.dropdown2 li:hover {
  color: #af11cd;
}

/* Ensure parent container is positioned relatively */
.list-item {
  position: relative;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .dropdown,
  .dropdown2 {
    position: static;
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: #000000;
    box-shadow: none;
    background-color: rgb(246, 243, 247);
  }
  .dropdown li:not(:last-child)::after,
  .dropdown2 li:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background-color: rgb(255, 255, 255);
  }

  .dropdown li,
  .dropdown2 li {
    justify-content: flex-start; /* Align items to the start on smaller screens */
  }
  .border-bb {
    border-bottom: 1px solid rgb(61, 60, 60);
  }
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .dropdown,
  .dropdown2 {
    position: static;
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: #000000;
    box-shadow: none;
    background-color: rgb(246, 243, 247);
  }
  .dropdown li:not(:last-child)::after,
  .dropdown2 li:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background-color: rgb(255, 255, 255);
  }

  .dropdown li,
  .dropdown2 li {
    justify-content: flex-start; /* Align items to the start on smaller screens */
  }
  .border-bb {
    border-bottom: 1px solid rgb(61, 60, 60);
  }
}
/* Responsive adjustments */
@media (max-width: 540px) {
  .dropdown,
  .dropdown2 {
    position: static;
    width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: #000000;
    box-shadow: none;
    background-color: rgb(246, 243, 247);
  }
  .dropdown li:not(:last-child)::after,
  .dropdown2 li:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background-color: rgb(255, 255, 255);
  }

  .dropdown li,
  .dropdown2 li {
    justify-content: flex-start; /* Align items to the start on smaller screens */
  }
  .border-bb {
    border-bottom: 1px solid rgb(61, 60, 60);
  }
}
/* css for mobile only */
.mobile-only-arrow {
  display: none; /* Hide arrow by default */
}

@media (max-width: 1024px) {
  .mobile-only-arrow {
    display: inline-block; /* Use inline-block for better alignment */
    font-size: 16px; /* Adjust font size for better proportions */
    font-weight: normal; /* Reduce boldness to match the text style */
    margin-left: 8px; /* Add spacing between text and the arrow */
    vertical-align: middle; /* Align the arrow with the text vertically */
  }
}

@media (max-width: 768px) {
  .mobile-only-arrow {
    display: inline-block; /* Use inline-block for better alignment */
    font-size: 16px; /* Adjust font size for better proportions */
    font-weight: normal; /* Reduce boldness to match the text style */
    margin-left: 8px; /* Add spacing between text and the arrow */
    vertical-align: middle; /* Align the arrow with the text vertically */
  }
}
@media (max-width: 540px) {
  .mobile-only-arrow {
    display: inline-block; /* Use inline-block for better alignment */
    font-size: 16px; /* Adjust font size for better proportions */
    font-weight: normal; /* Reduce boldness to match the text style */
    margin-left: 8px; /* Add spacing between text and the arrow */
    vertical-align: middle; /* Align the arrow with the text vertically */
  }
}

@media (max-width: 768px) {
  .h1formain {
    font-size: 30px;
    line-height: 1.4;
    margin-bottom: 16px;
  }
  .fs-16 {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 12px;
    text-align: justify;
  }
}

.fs-16 {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 12px;
  text-align: justify;
}

@media only screen and (width: 640px) and (height: 360px) {
  .scrollable {
    width: 100%;
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    border: 1px solid #ccc;
    scrollbar-width: none;
  }
}

@media only screen and (max-width: 1024px) and (not (width: 640px)) and (not (height: 360px)) {
  .scrollable {
    width: 100%;
    height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    border: 1px solid #ccc;
    scrollbar-width: none;
  }
}
@media (min-width: 992px) {
  .ms-lg-5 {
    margin-left: 2rem !important;
  }
}