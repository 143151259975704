/* Global table styles */
table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px; /* Add rounded corners */
  overflow: hidden; /* Ensures child elements follow the border radius */
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); Add shadow for depth */
}

/* Table header and data styles */
th,
td {
  padding: 12px !important;
  text-align: center;
  border-bottom: 3px solid #ddd;
}

/* Header styles */
th {
  font-weight: bold;
  /* background-color: #007BFF; */
  color: #5e5e5e !important; /* Change text color for better contrast */
  position: sticky; /* Make the header sticky */
  top: 0; /* Fix the header to the top */
  z-index: 10; /* Keep header above other content */
  font-weight: 600 !important;
  background-color: #f5f5f6;
  font-size: 14px !important;
}

/* Even row background color */
tr:nth-child(even) {
  background-color: #ffffff;
}

/* Hover effect for rows */
tr:hover {
  background-color: #ffffff; /* Change background on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
}

/* Responsive design for mobile devices */
@media (max-width: 600px) {
  th,
  td {
    padding: 8px; /* Reduce padding on smaller screens */
    font-size: 14px; /* Smaller font size */
  }
}

/* Global styles for Table Pagination */
.pagination-container {
  padding: 16px; /* Adjust padding */
  /* background-color: #f8f9fa;  */
  border-top: 1px solid #dee2e6; /* Top border */
  /* border-bottom: 1px solid #dee2e6;  */
  color: #333; /* Text color */
}

/* Styles for select dropdown */
.pagination-select {
  border-radius: 4px; /* Rounded corners for select dropdown */
  background-color: #ffffff; /* Background color for select */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

/* Styles for pagination action buttons */
.pagination-actions {
  color: #007bff; /* Button color */
}

.pagination-actions .MuiButtonBase-root:hover {
  background-color: #e2e6ea; /* Button hover background */
}
